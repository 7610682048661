<template>
  <div class="v-input">
    <v-text-field
      v-show="!showEditableInput"
      ref="display"
      @mouseover="toggleComponent"
      :single-line="singleLine"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :label="label"
      :value="value|currency(currency)"
      :error="!!internalErrorMessages.length"
      :error-messages="internalErrorMessages"
      :reverse="!reverse"
      :append-icon="computedAppendIcon"
      :prepend-inner-icon="computedPrependInnerIcon"
      @click:append="$emit(`click:${reverse?'append':'prepend-inner'}`, $event)"
      @click:prepend-inner="$emit(`click:${reverse?'prepend-inner':'append'}`, $event)"
    />
    <v-text-field
      v-show="showEditableInput"
      class="red--text"
      ref="input"
      type="number"
      :single-line="singleLine"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :label="label"
      v-model="value"
      :rules="rules"
      :error-messages="errorMessages"
      :reverse="!reverse"
      :append-icon="computedAppendIcon"
      :prepend-inner-icon="computedPrependInnerIcon"
      @input="propagateInput"
      @focus="preventToggleComponent = true"
      @blur="allowToggleComponent"
      @mouseout="toggleComponent"
      @click:append="$emit(`click:${reverse?'append':'prepend-inner'}`, $event)"
      @click:prepend-inner="$emit(`click:${reverse?'prepend-inner':'append'}`, $event)"
      @update:error="computeErrorMessages()"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  data () {
    return {
      internalErrorMessages: [...(this.errorMessages ? [this.errorMessages] : [])],
      preventToggleComponent: false,
      showEditableInput: false
    }
  },
  mounted () {
    this.$refs.input.form?.validate()
    this.computeErrorMessages()
  },
  methods: {
    allowToggleComponent () {
      if (this.value === null) { return }
      this.preventToggleComponent = false
      this.$nextTick(this.toggleComponent)
    },
    computeErrorMessages () {
      this.internalErrorMessages = [...(this.errorMessages ? [this.errorMessages] : []), ...(this.$refs?.input?.errorBucket ?? [])]
    },
    propagateInput (e) {
      this.$emit('input', e)
    },
    toggleComponent () {
      if (this.$refs.display.isReadonly || this.preventToggleComponent) { return }
      this.computeErrorMessages()
      this.showEditableInput = !this.showEditableInput
    }
  },
  computed: {
    computedAppendIcon () {
      return this.reverse ? this.appendIcon : this.prependInnerIcon
    },
    computedPrependInnerIcon () {
      return this.reverse ? this.prependInnerIcon : this.appendIcon
    },
    value: {
      get () {
        return typeof this.amount === 'number' ? this.amount : this.emptyValue
      },
      set (val) {
        if (val.length) {
          this.$emit('update:amount', Number(val))
        } else {
          this.$emit('update:amount', this.emptyValue)
        }
      }
    }
  },
  props: {
    appendIcon: String,
    prependInnerIcon: String,
    currency: [Object, String],
    label: String,
    amount: Number,
    dense: Boolean,
    singleLine: Boolean,
    rules: Array,
    errorMessages: String,
    disabled: Boolean,
    readonly: Boolean,
    reverse: Boolean,
    emptyValue: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped></style>
